import { Game } from '@/enums';
import Airship from './airship';
import Engine from './engine';

const ROTATION_DEGREES = 5;

export default class Player {
  constructor({ context, width, height, settings, state, onCrash }) {
    this.context = context;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.state = state;
    this.onCrash = onCrash;
    this.hasCollided = false;

    this.init(settings);
    this.create();
  }

  init(settings) {
    const {
      baseVelocity,
      fallVelocity,
      jumpVelocity,
      gravityForceMultiplier,
      moveScreenMultiplier,
    } = settings;

    Object.assign(this, {
      baseVelocity,
      fallVelocity,
      jumpVelocity,
      gravityForceMultiplier,
      moveScreenMultiplier,
    });
  }

  create() {
    this.x = 0.1 * this.canvasWidth;
    this.y = 0;
    this.fallingCounter = new Date();
    this.velocityAmount = this.moveScreenMultiplier * this.canvasHeight;
    this.velocity = this.baseVelocity * this.velocityAmount;
    this.gravityForce = this.gravityForceMultiplier * this.velocityAmount;

    this.airship = new Airship({ context: this.context, width: this.canvasWidth, height: this.canvasHeight });
    this.engine = new Engine({ context: this.context, width: this.canvasWidth, height: this.canvasHeight, airship: this.airship });
  }

  render() {
    this.draw();

    if (this.state.isPlaying) {
      this.fall();
    }
  }

  draw() {
    if (!this.hasCollided) {
      this.context.save();
      this.context.rotate(ROTATION_DEGREES * Math.PI / 180);
    }

    this.airship.render({ x: this.x, y: this.y });
    this.engine.draw(this.hasCollided);
    this.airship.draw(this.hasCollided);

    if (!this.hasCollided) {
      this.context.restore();
    }
  }

  pause() {
    this.velocity = this.baseVelocity * this.velocityAmount;
  }

  reset() {
    this.pause();
    this.y = 0;

    this.draw();
  }

  jump() {
    if (!this.hasCollided) {
      this.velocity = this.velocity + this.jumpVelocity * this.velocityAmount;
    }
  }

  fall() {
    var now = Date.now();

    if (now - this.fallingCounter <= 1000 / Game.FPS) {
      return;
    }

    if (this.velocity < 4 * this.baseVelocity) {
      this.velocity += this.gravityForce;
    }

    const speed = this.hasCollided ? this.fallVelocity * 2 : this.fallVelocity;
    this.y += this.velocity * speed;
    
    if (this.y < 0) {
      this.y = 0;
      this.velocity = 2 * this.velocityAmount;
    }

    if (this.y > this.canvasHeight) {
      this.onCrash();
      this.reset();
    }
  }

  setCollision(value) {
    this.hasCollided = value;
  }
}
