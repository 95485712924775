export const BASE_SPEED = 2;
export const ENEMY_ROTATE_SIZE_MULTIPLIER = 0.4;
export const FPS = 30;
export const GAME_AREA_SIZE_MULTIPLIER = 0.9;
export const PLAYER_GRAVITY_FORCE_MULTIPLIER = 0.15;
export const PLAYER_AND_ENEMY_MULTIPLIER = 0.2;
export const PLAYER_BASE_VELOCITY = 2;
export const PLAYER_FALL_VELOCITY = 1;
export const PLAYER_JUMP_VELOCITY = -6;
export const PLAYER_MOVE_SCREEN_MULTIPLIER = 0.002;
export const PLAYER_ROTATE_SIZE_MULTIPLIER = 0.4;
export const PREFIXES = { images: '/images', sound: '/sound' };
